<script>
import { SuawMainContent, SuawParagraph, SuawHeading } from "@suaw/suaw-component-library";
import ArticleFrame from "@/features/articles/components/ArticleFrame.vue";

export default {
  name: "StayingCreativeWhileTheWorldIsBurning",
  components: {
    SuawMainContent,
    SuawParagraph,
    // eslint-disable-next-line vue/no-unused-components
    SuawHeading,
    ArticleFrame
  }
};
</script>

<template>
  <SuawMainContent size="large">
    <ArticleFrame article-id="StayingCreativeWhileTheWorldIsBurning">
      <SuawParagraph
        text="This ain’t no party, this ain’t no disco,<br/>This ain’t no fooling around<br/>No time for dancing, or lovey dovey,<br/>I ain’t got time for that now<br/>– Talking Heads, “Life During Wartime”"
      />
      <SuawParagraph
        text="It’s difficult. Let me just say that at the beginning. Creativity can be a struggle even when you have equilibrium in your life. But when daily challenges disrupt your mental and emotional balance, finding time to write, paint, or play music feels impossibly difficult."
      />
      <SuawParagraph
        text="As I write this in San Francisco in September 2020, we are quite literally surrounded by wildfires. This morning the sky is red with smoke, the automatic street lights are still on at 11 a.m., and it’s raining ash. But we’re also in the midst of a society-defining pandemic, the most consequential American election in decades, and social upheaval to acknowledge that Black lives matter."
      />
      <SuawParagraph
        text="All of that is lurking outside our doors, in our homes, and taking up space in our minds. Inside, many of us are working from home while trying to keep our kids focused on academic achievement via Zoom. Some are keeping the medical, food, and safety systems running — you are heroes. Social gatherings are distant, muffled, and sweaty because of our masks. Even watching Disney’s “Mulan” is a political decision."
      />
      <SuawParagraph
        text="This is how you stay creative while all of that is swirling around you: remember that everything, difficult or easy, is a test of your determination and purpose. This is life during wartime, and you are a journalist at the event. Whether you are writing a newspaper article or a poem, your words and stories matter. They help you process the turbulence, make sense of the chaos, and bring order and deliberation to your days. You do all this by simply sitting down and saying to yourself, “Now I write.”"
      />
      <SuawParagraph
        text="I’m attempting to create that order through my current science fiction project. Even though it’s a story set 450 years in the future, it is very much a product of the current moment. My main character is Black, in part because I want more diversity in science fiction (and I secretly imagined Chadwick Boseman playing him in the movie version, but that’s a different story.) This forces me to examine what life is like for Black men now, so I can extrapolate that into the future. Even though I wrote the outline for this story almost 20 years ago, writing it now has helped me focus on the social upheaval and growth that surrounds me. I’m no Shakespeare, who is reputed to have written “King Lear” under a stay-at-home order during a plague. But the words I write, and the stories I tell, are part of the zeitgeist of today."
      />
      <SuawParagraph
        text="Fully invest yourself in this moment and don’t shy away from the discomfort. Empathy can change your life. Ask yourself: Why are people protesting? Why is this election such a historical inflection point? What about people for whom this level of catastrophe is a daily experience? Write, and let that inform your writing. The world is always on fire somewhere. If it’s only now making you uncomfortable, then you get to examine your privilege and why you have been insulated from the worst of it."
      />
      <SuawParagraph
        text="Luckily, you’re an artist. You get to write about it, sketch it, sculpt it, create a dance that embodies the confusion and chaos. You get to be a historian for this moment, even if what you write is not directly related to current events. This is how you can focus yourself during all this disruption and distill the cacophony into some sort of order."
      />
      <SuawParagraph
        text="Is it more difficult to find a creative spark? Maybe. Is it more difficult to create the space to work? Absolutely, particularly if you live with family or roommates, and none of them ever leave the house! Is it more difficult to find the time? Yes. But it’s worth it. It might be challenging to give priority to your creative endeavors, but try to find time, even if it’s only five or 10 minutes a day."
      />
      <SuawParagraph
        text="You are tired. You are stressed. You are uncertain. But you can make time to create. Every 10 minutes you spend opening yourself to your story can be a healing experience, even if your art is only for you. We all yearn for respite, and the arts offer us the space to imagine a less chaotic, overwhelming world."
      />
      <SuawParagraph
        text="It’s life during wartime, the world is on fire, and we need to hear your story, to hear your song, to watch your dance, to hear you recite your poem, your voice cracking with anger, fear, and hope."
      />
      <SuawParagraph text="Write." />
      <SuawParagraph text="Dance." />
      <SuawParagraph text="Play." />
      <SuawParagraph text="Act." />
      <SuawParagraph text="Create." />
    </ArticleFrame>
  </SuawMainContent>
</template>
